.container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 43px;
  width: 100%;
  height: 100%;
  padding: 30px 64px;
}

.content > :first-child {
  margin-top: 0;
}

.sliderBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  width: 40px;
  height: 40px;
  min-width: 0;

  border: none;
  border-radius: 50%;
  outline: none;

  color: var(--text-10);
  background-color: var(--shape-40);

  padding: 15px;
}

.sliderBtnPrev {
  left: 0;
}

.sliderBtnNext {
  right: 0;
}

.sliderIndicators {
  cursor: pointer;
  border: none;
  outline: none;
  min-width: 0;
  width: 16px;
  padding: 8px 4px;

  &:hover {
    background-color: transparent;
  }

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid var(--text-10);
  }

  &.active::before {
    background-color: var(--text-10);
  }
}
